import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Spinner } from "reactstrap";
import { getOrganizations } from "../../../api";
import { getProfileQuery } from "../../../queries";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: profileData, isLoading } = useQuery({
    ...getProfileQuery(),
  });

  const OrganizationEditPage = () => {
    navigate("/organization/edit");
  };

  const profileId = profileData?.result?.organizationId;
  const { data, isLoading: IsLoadingId } = useQuery({
    queryKey: ["organizations", profileId],
    queryFn: () => getOrganizations(profileId),
    enabled: !!profileId,
  });

  const formatAccountNumber = (accountNumber) => {
    if (!accountNumber || accountNumber.length < 8) {
      return accountNumber;
    }
    const start = accountNumber.slice(0, 4);
    const end = accountNumber.slice(-4);
    const middle = "*".repeat(accountNumber.length - 12);
    return `${start} ${middle} ${end}`;
  };

  const ProfileStatus = () => {
    switch (data?.result?.state) {
      case 0:
        return t("Created");
      case 1:
        return t("Active");
      case 2:
        return t("Inactive");
      case 3:
        return t("Blocked");
      case 4:
        return t("Deleted");
    }
  };
  const getProductSaleTypeNames = (productSaleTypes) => {
    const types = [];
    if (productSaleTypes & 2) types.push(t("Compensation"));
    if (productSaleTypes & 1) types.push(t("Installment"));
    if (productSaleTypes & 4) types.push(t("Sale"));
    if (productSaleTypes & 8) types.push(t("UN"));
    return types.join(", ");
  };
  return (
    <div className="page-content">
      {isLoading || IsLoadingId ? (
        <Spinner />
      ) : (
        <div>
          <h1 className="mt-5">{t("Partner profile")}</h1>
          <div className="d-flex justify-content-between gap-5 p-3">
            <Card
              className="w-50 p-3 cursor-pointer"
              onClick={OrganizationEditPage}
            >
              <i className=" ri-briefcase-4-line text-success fs-1"></i>
              <span style={{ color: "gray" }}>
                {t("Name of the organization")}
              </span>
              <div
                className="d-flex align-items-start gap-1"
                style={{ fontWeight: 500, fontSize: "18px" }}
              >
                <p>{data?.result?.information?.type?.name}</p>
                <span>"{data?.result?.information?.organizationName}"</span>
              </div>
            </Card>
            <Card
              className="w-50 d-flex cursor-pointer align-items-start p-3"
              onClick={OrganizationEditPage}
            >
              <i className=" ri-flashlight-line text-info fs-1"></i>
              <span style={{ color: "gray" }}>{t("Status")}</span>
              <div
                className="d-flex align-items-start gap-1"
                style={{ fontWeight: 500, fontSize: "18px" }}
              >
                <p>{ProfileStatus()}</p>
              </div>
            </Card>
            <Card
              className="w-50 p-3 cursor-pointer"
              onClick={OrganizationEditPage}
            >
              <i
                className=" ri-bank-line  fs-1"
                style={{ color: "#631AD9" }}
              ></i>
              <span style={{ color: "gray" }}>{t("Bank details")}</span>
              <div
                className="d-flex align-items-start gap-1"
                style={{ fontWeight: 500, fontSize: "18px" }}
              >
                <p>{formatAccountNumber(data?.result?.bank?.accountNumber)}</p>
              </div>
            </Card>
          </div>
          <div className="d-flex justify-content-between gap-5 p-3">
            <Card
              className="w-50 p-3 cursor-pointer"
              onClick={OrganizationEditPage}
            >
              <i
                className="  ri-team-line   fs-1"
                style={{ color: "#E81694" }}
              ></i>
              <span style={{ color: "gray" }}>{t("Employees")}</span>
              <span style={{ fontWeight: 500, fontSize: "18px" }}>
                {data?.result?.statistics?.employeesCount}
              </span>
            </Card>
            <Card
              className="w-50 p-3 cursor-pointer"
              onClick={OrganizationEditPage}
            >
              <i
                className="  ri-function-line fs-1"
                style={{ color: "#FFA800" }}
              ></i>
              <span style={{ color: "gray" }}>{t("Catalog")}</span>
              <span style={{ fontWeight: 500, fontSize: "18px" }}>
                {data?.result?.statistics?.productsCount === 0
                  ? t("No Products")
                  : data?.result?.statistics?.productsCount + " " + t("Product")}
              </span>
            </Card>
            <Card className="w-50 p-3 ">
              <i
                className="ri-barcode-fill fs-1"
                style={{ color: "#005BFF" }}
              ></i>
              <span style={{ color: "gray" }}>
                {t("Registered transaction types")}
              </span>

              <span style={{ fontWeight: 500, fontSize: "18px" }}>
                {getProductSaleTypeNames(data?.result?.productSaleTypes)}
              </span>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;

import logoArbuz from "../../src/assets/images/svg/arbuz_full.svg";
import logoArbuzSm from "../../src/assets/images/svg/arbuz.svg";
import logoTaqsim from "../../src/assets/images/svg/taqsimLogo.svg";
import logoTaqsimSm from "../../src/assets/images/svg/logoSm.svg";
import logoProfi from "../../src/assets/images/svg/profiLogo_full.svg"
import logoProfiSm from "../../src/assets/images/svg/profiLogoSm.svg"
import logoTaqsimTitle from "../../src/assets/images/svg/taqsimLogoTitle.svg"
import logoProfiTitle from "../../src/assets/images/svg/profiLogoTitle.svg"
import logoArbuzTitle from "../../src/assets/images/svg/arbuzLogoTitle.svg"

export const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  LOGOS: {
    orzu: logoProfi ,
    food: logoArbuz ,
    taqsim: logoTaqsim ,
  },
  LOGOS_SM: {
    orzu: logoProfiSm ,
    food: logoArbuzSm ,
    taqsim: logoTaqsimSm ,
  },
  LOGOS_TITLE: {
orzu: logoProfiTitle,
food: logoArbuzTitle,
taqsim: logoTaqsimTitle,
  },
  TITLES: {
    orzu: "Profi Market | Business",
    food: "Arbuz market | Business",
    taqsim: "Taqsim Market | Business",
  },
  SITENAME: process.env.REACT_APP_PUBLIC_SITENAME,
   
  currency: function () {
    return this.SITENAME === "food" ? "aed" : "sum";
  },
};

import React, { useEffect } from "react";
import QueryProvider from "./providers/query-provider";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";

// Fake Backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { config } from "./utils/config";

// Activating fake backend
fakeBackend();


  document.title = config.TITLES[config.SITENAME] || 'Taqsim';
  const faviconUrl = config.LOGOS_TITLE[config.SITENAME] || config.LOGOS_TITLE.taqsim;

  const link = document.querySelector('link[rel="icon"]');
  if (link) {
    link.href = faviconUrl; 
  }
  

function App() {
  return (
    <React.Fragment>
      <QueryProvider>
        <Route />
      </QueryProvider>
    </React.Fragment>
  );
}

export default App;

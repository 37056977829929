import { useQuery } from "@tanstack/react-query";
import {
  getBrands,
  getCategories,
  getClients,
  getContracts,
  getEmployees,
  getModeration,
  getModerationId,
  getOrders,
  getOrders2,
  getOrganizations,
  getPosition,
  getProducts,
  getProfile,
  getProfileInfo,
  getReferencesFormal,
  getRegions,
  getReserveById,
  getReserveSubOrdersById,
  getReview,
} from "../api/index";

export function getOrdersQuery(params) {
  return {
    queryKey: ["orders", params],
    queryFn: async () => getOrders(params),
  };
}

export function getOrders2Query(params) {
  return {
    queryKey: ["orders2", params],
    queryFn: async () => getOrders2(params),
  };
}

export function getReserveSubOrdersByIdQuery(id, subOrderId) {
  return {
    queryKey: ["orders2-single", id, subOrderId],
    queryFn: async () => getReserveSubOrdersById(id, subOrderId),
  };
}

export function getReserveByIdQuery(id) {
  return {
    queryKey: ["reserve-id", id],
    queryFn: async () => getReserveById(id),
  };
}

export function getModerationsQuery(params) {
  return {
    queryKey: ["moderations", params],
    queryFn: async () => getModeration(params),
  };
}

export function getModerationsIdQuery(id) {
  return {
    queryKey: ["moderations", id],
    queryFn: async () => getModerationId(id),
  };
}

export function getProductsQuery(params) {
  return {
    queryKey: ["products", params],
    queryFn: async () => getProducts(params),
  };
}

export function getContractsQuery(params) {
  return {
    queryKey: ["contracts", params],
    queryFn: async () => getContracts(params),
  };
}

export function getProfileQuery(params) {
  return {
    queryKey: ["profile", params],
    queryFn: async () => getProfile(params),
  };
}

export function getProfileInfoQuery(params) {
  return {
    queryKey: ["profileInfo", params],
    queryFn: async () => getProfileInfo(params),
  };
}

export function getClientsQuery(params) {
  return {
    queryKey: ["clients", params],
    queryFn: async () => getClients(params),
  };
}

export function getEmployessQuery(params) {
  return {
    queryKey: ["employees", params],
    queryFn: async () => getEmployees(params),
  };
}

export function getPositionQuery(params) {
  return {
    queryKey: ["position", params],
    queryFn: async () => getPosition(params),
  };
}

export function getReferenceFormalQuery(params) {
  return {
    queryKey: ["reference-formal", params],
    queryFn: async () => getReferencesFormal(params),
  };
}

export function getRegionsQuery(params) {
  return {
    queryKey: ["regions", params],
    queryFn: async () => getRegions(params),
  };
}

export function getCategoriesQuery(params) {
  return {
    queryKey: ["categories", params],
    queryFn: async () => getCategories(params),
  };
}

export function getReviewQuery(params) {
  return {
    queryKey: ["review", params],
    queryFn: async () => getReview(params),
  };
}

export function getBrandsQuery(params) {
  return {
    queryKey: ["brands", params],
    queryFn: async () => getBrands(params)
  }
}

export function getOrganizationQuery(id) {
  return {
    queryKey: ["organizationId", id],
    queryFn: async () => getOrganizations(id)
  }
}
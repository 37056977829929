import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Form,
  Input as ReactInput,
  Label,
  Spinner,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { t } from "i18next";
import StateSelect from "../Components/StateSelect";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { Input } from "../../../Components/atoms/input";
import { Controller, useFieldArray } from "react-hook-form";
import {
  ProductsPut,
  attributeAdditionDeleteId,
  getAttributesId,
  getProductsId,
  uploadFiles,
} from "../../../api";
import Dropzone from "react-dropzone";
import Visibility from "../Components/Visibility";
import schemaFieldTypes from "../../../utils/validationSchema";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import { Textarea } from "../../../Components/atoms/textarea";
import { InputLabel } from "../../../Components/atoms/inputLabel";
import { layoutModeTypes } from "../../../Components/constants/layout";
import { config } from "../../../utils/config";
import Select from "react-select";
import { getBrandsQuery, getOrganizationQuery } from "../../../queries";
const values = {
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  categoryId: null,
  attrs: [],
  prices: [
    { type: "Price", value: "" },
    { type: "Vat", value: "" },
    { type: "Sale", value: "" },
  ],
  isVisible: null,
  files: [],
};
const saleType = 
  {
    1: "Рассрочка",
    2: "Компенсация",
    4: "Продажа",
    8: "ООН"
  }
const ProductsEdit = () => {
  const schema = createSchema({
    name: "name2",
    description: "description2",
    saleType: "saleType",
    files: "files",
    // brandId: "select",
  });

  const { id } = useParams();

  const [filePreviews, setFilePreviews] = useState([]);
  const [fileInputs, setFileInputs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [saleTypeState, setSaleTypeState] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [attribute, setAttribute] = useState([]);
  const [attributeLoading, setAttributeLoading] = useState({});
  const [attributeValues, setAttributeValues] = useState([]);
  const [attributeShow, setAttributeShow] = useState(true);
  const errorMessageRef = useRef(null);
  const maxFileSize = 10 * 1024 * 1024;
  const navigate = useNavigate();
  const clients = useQueryClient();
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    setError,
    formState: { errors },
    clearErrors
  } = useHookForm(values, schema);
  const { data } = useQuery({
    queryKey: ["productsEdit", id],
    queryFn: () => getProductsId(id),
  });
  const { data: brands } = useQuery({
    ...getBrandsQuery(),
  });
  const { mutate } = useMutation({
    mutationFn: (id) => attributeAdditionDeleteId(id),
  });
  const toggleAttribute = () => {
    setAttributeShow(!attributeShow);
  };
  const products = data?.result;
  const categoryId = data?.result?.categoryId;
  const { data: attributes } = useQuery({
    queryKey: ["categoryId", categoryId],
    queryFn: () => getAttributesId(categoryId, {page: 1, size: 2147483647}),
    enabled: !!categoryId,
  });  const {data: organization} =  useQuery({
    ...getOrganizationQuery(products?.organizationId),
    enabled: !!products
  })
  const getFilterSaleType = (productSaleType) => {
   return Object.keys(saleType).map(Number).filter((type) => productSaleType & type)  
  }
  
  const productSaleTypes = getFilterSaleType(organization?.result?.productSaleTypes)
  const allPrice = products?.variations?.[0].prices?.filter(
    (item) =>
      item.type !== "Price" && item.type !== "Vat" && item.type !== "Sale"
  );
console.log(productSaleTypes, "productSaleTypes");

  function getLastPartOfUrl(url) {
    if (url.length > 36) {
      const parts = url.split("/");
      return parts[parts.length - 1];
    } else {
      return url;
    }
  }

  const onDropAccepted = (acceptedFiles) => {
    if (errorMessageRef.current) {
      errorMessageRef.current.textContent = "";
    }
    handleFileUpload(acceptedFiles);
  };

  const onDropRejected = (rejectedFiles) => {
    const hasFileTooLarge = rejectedFiles.some((file) =>
      file.errors.some((error) => error.code === "file-too-large")
    );

    if (hasFileTooLarge) {
      if (errorMessageRef.current) {
        const fileSizeInMB = (
          rejectedFiles[0].file.size /
          (1024 * 1024)
        ).toFixed(2);
        errorMessageRef.current.textContent =
          t("File size must not exceed 10 MB!") +
          " " +
          `File size ${fileSizeInMB} MB`;
      }
    }
  };

  const handleFileUpload = (acceptedFiles) => {
    const newFiles = [...watch("files")];
    const updatedPreviews = [...filePreviews];
    const updatedInputs = [...fileInputs];

    acceptedFiles.forEach((file, index) => {
      newFiles.push({ file });

      updatedPreviews.push(URL.createObjectURL(file));

      updatedInputs.push(updatedInputs.length);
    });

    setValue("files", newFiles);
    setFilePreviews(updatedPreviews);
    setFileInputs(updatedInputs);
  };

  const handleDeleteImage = (index) => {
    const currentFiles = [...watch("files")];
    const deletedFile = currentFiles[index];
    const filteredFiles = currentFiles.filter((file, idx) => idx !== index);
    setValue("files", filteredFiles);
    setDeletedFiles((prev) => [...prev, deletedFile]);
    const updatedImageFiles = imageFiles.filter((_, idx) => idx !== index);
    setImageFiles(updatedImageFiles);
    const updatedPreviews = filePreviews.filter((_, idx) => idx !== index);
    setFilePreviews(updatedPreviews);
    const updatedInputs = fileInputs.filter((_, idx) => idx !== index);
    setFileInputs(updatedInputs);
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (index) => {
    if (draggedIndex === null || draggedIndex === index) return;

    const updatedPreviews = [...filePreviews];
    const draggedPreview = updatedPreviews[draggedIndex];
    updatedPreviews.splice(draggedIndex, 1);
    updatedPreviews.splice(index, 0, draggedPreview);

    const updatedFiles = [...watch("files")];
    const draggedFile = updatedFiles[draggedIndex];
    updatedFiles.splice(draggedIndex, 1);
    updatedFiles.splice(index, 0, draggedFile);

    setFilePreviews(updatedPreviews);
    setValue("files", updatedFiles);

    setDraggedIndex(null);
  };
  const saleTypeOptions = [
    { id: "pre-order", value: 1, label: "Рассрочка" },
    { id: "order-available", value: 2, label: "Компенсация" },
    { id: "order-in-2-4-weeks", value: 4, label: "Продажа" },
    { id: "BMT", value: 8, label: "ООН" },
  ];

  let languages = ["ru", "uz-Latn-UZ", "uz-Cyrl-UZ"];
  let priceType = ["Price", "Vat", "Sale"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  // const handleSaleTypeChange = (type) => {
  //   const saleTypeValues = Array.isArray(watch("saleType"))
  //     ? watch("saleType")
  //     : [];
  //   const updatedSaleType = saleTypeValues.includes(type)
  //     ? saleTypeValues.filter((t) => t !== type && productSaleTypes.includes(t))
  //     : [...saleTypeValues, type];
  //   setValue("saleType", updatedSaleType);
  // };
  const handleSaleTypeChange = (type) => {
    const saleTypeValues = Array.isArray(watch("saleType")) ? watch("saleType") : [];
    const updatedSaleType = saleTypeValues.includes(type)
      ? saleTypeValues.filter((t) => t !== type && productSaleTypes.includes(t))
      : [...saleTypeValues, type].filter((t) => productSaleTypes.includes(t)); 
    setValue("saleType", updatedSaleType);
  };
  const getInitialSaleTypeState = (productSaleTypes) => {
    const filteredSaleTypes = getFilterSaleType(productSaleTypes);
    const currentSaleTypeValues = Array.isArray(watch("saleType")) ? watch("saleType") : [];
  
   
    console.log("Filtered Sale Types:", filteredSaleTypes);
    console.log("Current Sale Type Values:", currentSaleTypeValues);
  
    if (
      currentSaleTypeValues.length > 0 &&
      currentSaleTypeValues.every((val) => filteredSaleTypes.includes(val))
    ) {
      return currentSaleTypeValues;
    }
  
    return filteredSaleTypes; 
  };
  
const calculateSaleType = () => {
  const saleTypeValues = Array.isArray(watch("saleType")) ? watch("saleType") : [];
  return saleTypeValues.reduce((acc, type) => acc + type, 0);
};
  const Submit = async (res) => {
    if (!res?.prices?.[0].value) {
      return setError("prices[0].value", {
        type: "manual",
        message: t("required_field_error"),
      });
    }

    if (
      res.prices[2].value !== "" &&
      +res.prices[0].value > +res.prices[2].value &&
      +res.prices[2].value !== 0
    ) {
      return setError("prices[2].value", {
        type: "manual",
        message: t("price_2_error"),
      });
    }
    setLoading(true);

    try {
      const newFiles = watch("files");

      const uploadedFiles = [];
      const uploadedFileUrls = new Set();

      for (let index = 0; index < newFiles.length; index++) {
        const currentFile = newFiles[index];
        const hasPreview = currentFile?.url;

        if (!hasPreview) {
          const file = currentFile.file;

          if (uploadedFileUrls.has(file.name)) {
            continue;
          }

          const formData = new FormData();
          formData.append("files", file);

          const resImage = await uploadFiles(formData);
          const imageObj = resImage?.[0];

          uploadedFileUrls.add(file.name);
          uploadedFiles.push({
            order: index + 1,
            url: imageObj?.url,
            fileId: imageObj?.id,
            variationId: products?.id,
            isVisible: true,
            productId: products?.variations?.[0]?.productId,
          });
        } else {
          uploadedFiles.push({
            ...newFiles[index],
          });
        }
      }
      const names = languages.map((languageCode) => ({
        languageCode,
        text: watch(`name.${languageCode}`),
      }));

      const descriptions = languages.map((languageCode) => ({
        languageCode,
        text: watch(`description.${languageCode}`),
      }));

      const customAttributes = attribute.map((attr) => ({
        attributeId: attr.attributeId || null,
        value: attr.value || "",
        productId: null,
        valueTranslations: languages.map((languageCode) => ({
          languageCode,
          text: attr.valueTranslations?.[languageCode] || "",
        })),
        variationId: null,
        attribute: {
          weight: 0,
          dataType: "Text",
          hasFilter: false,
          isValueTranslated: true,
          isAdditional: true,
          isRequired: true,
          name: languages.map((languageCode) => ({
            languageCode,
            text: attr.attribute?.name?.[languageCode] || "",
          })),
          description: languages.map((languageCode) => ({
            languageCode,
            text: attr.attribute?.description?.[languageCode] || "",
          })),
          id: null,
          isVisible: true,
          categoryId: 43,
        },
      }));

      const variations = [
        {
          count: 1000,
          compensationOnly: false,
          productId: id,
          attributeValues: [
            ...(customAttributes || []),
            ...Object.keys(res.attrs).map((attrId) => {
              const attr = res.attrs[attrId];
              const attributeItem = products.variations[0].attributeValues.find(
                (item) =>
                  item.attribute.isAdditional &&
                  item.attributeId === parseInt(attrId)
              );

              return {
                attributeId: parseInt(attrId),
                value: attr.value || "",
                productId: id,
                isVisible: true,
                valueTranslations: languages.map((languageCode) => ({
                  languageCode,
                  text: attr.valueTranslations?.[languageCode] || "",
                })),
                variationId: products.variations[0].id,
                attribute: attributeItem
                  ? {
                      weight: 0,
                      dataType: "Text",
                      hasFilter: false,
                      isValueTranslated: true,
                      isAdditional: true,
                      isRequired: true,
                      name: languages.map((languageCode) => ({
                        languageCode,
                        text: attr.attribute?.name?.[languageCode] || "",
                      })),
                      description: languages.map((languageCode) => ({
                        languageCode,
                        text: attr.attribute?.description?.[languageCode] || "",
                      })),
                      id: parseInt(attrId),
                      isVisible: true,
                      categoryId: 43,
                    }
                  : null,
              };
            }),
          ],
          prices: [
            ...(res.prices?.map((item) => ({
              currencyId: 1,
              value: item.value === "" ? 0 : +item.value,
              variationId: products?.variations?.[0]?.id,
              type: item.type,
            })) || []),
            ...(allPrice?.map((item) => ({
              currencyId: 1,
              value: item.value === "" ? 0 : +item.value,
              variationId: products?.variations?.[0]?.id,
              type: item.type,
            })) || []),
          ],

          isVisible: products?.isVisible,
          files: uploadedFiles,
          saleType: calculateSaleType(),
        },
      ];

      const datas = {
        brandId: !watch("brandId")?.value ? 1 : watch("brandId")?.value,
        description: descriptions,
        name: names,
        productId: id,
        variations: variations,
      };

      await ProductsPut(datas);
      navigate("/products-moderate");
      clients.invalidateQueries({ queryKey: ["products"] });
      clients.invalidateQueries({ queryKey: ["moderations"] });
      clients.invalidateQueries({ queryKey: ["categoryId"] });
    } catch (error) {
      alert(error?.data?.errors?.errorMessage);
    } finally {
      setLoading(false);
    }
  };


  const getTextByLanguageCode = (descriptions, languageCode) => {
    const description = descriptions.find(
      (desc) => desc.languageCode === languageCode
    );
    return description ? description.text : "";
  };

  useEffect(() => {
    if (products) {
      if (products.variations[0].attributeValues) {
        setAttributeValues(products.variations?.[0]?.attributeValues);
      }
      setValue(
        "brandId",
        products?.brand?.id === 1
          ? { value: 1, label: t("no_brand") }
          : { value: products?.brand?.id, label: products?.brand?.name }
      );

      languages.forEach((lang) => {
        setValue(
          `description.${lang}`,
          getTextByLanguageCode(products.descriptions, lang) || ""
        );
        setValue(`name.${lang}`, getTextByLanguageCode(products.names, lang) || "");
      });

      products.variations?.[0]?.attributeValues?.forEach((attrValue) => {
        if (attrValue.attribute.names) {
          languages.forEach((lang) => {
            setValue(
              `attrs.${attrValue.attributeId}.attribute.name.${lang}`,
              getTextByLanguageCode(attrValue.attribute.names, lang)
            );
          });
        }

        setValue(`attrs.${attrValue.attributeId}.value`, attrValue.value || "");

        if (attrValue.valueTranslations) {
          languages.forEach((lang) => {
            setValue(
              `attrs.${attrValue.attributeId}.valueTranslations.${lang}`,
              getTextByLanguageCode(attrValue.valueTranslations, lang)
            );
          });
        }
      });

      const existingFiles = products?.variations?.[0]?.files || [];
      const previews = existingFiles.map((file) => file.url);
      setFilePreviews(previews);
      setFileInputs([...Array(existingFiles.length + 1).keys()]);

      const formattedExistingFiles = existingFiles.map((file) => ({
        order: file.order,
        url: file.url,
        fileId: getLastPartOfUrl(file.url),
        variationId: products?.id,
        productId: products?.variations?.[0]?.productId,
        isVisible: true,
      }));

      setValue("files", formattedExistingFiles);

      priceType.forEach((type, index) => {
        const price = products?.variations?.[0]?.prices.find(
          (price) => price.type === type
        );
        setValue(`prices[${index}].value`, price?.value || 0);
      });
      if (products?.variations?.[0]?.saleType !== undefined) {
        const initialSaleTypes = getInitialSaleTypeState(products.variations[0].saleType);
        console.log(initialSaleTypes, "initialSaleTypes");
        
        setValue("saleType", initialSaleTypes);
      }
      
    }
  }, [products, setValue]);

  const addAttribute = () => {
    const nameFields =
      config.SITENAME === "food"
        ? {
            ru: "",
            en: "",
            "uz-Latn-UZ": "",
          }
        : {
            ru: "",
            "uz-Latn-UZ": "",
            "uz-Cyrl-UZ": "",
          };

    const descriptionFields =
      config.SITENAME === "food"
        ? {
            ru: "",
            en: "",
            "uz-Latn-UZ": "",
          }
        : {
            ru: "",
            "uz-Latn-UZ": "",
            "uz-Cyrl-UZ": "",
          };

    const valueTranslationsFields =
      config.SITENAME === "food"
        ? {
            ru: "",
            en: "",
            "uz-Latn-UZ": "",
          }
        : {
            ru: "",
            "uz-Latn-UZ": "",
            "uz-Cyrl-UZ": "",
          };
    setAttribute([
      ...attribute,
      {
        attribute: {
          weight: 0,
          dataType: "Text",
          hasFilter: false,
          isValueTranslated: true,
          isAdditional: true,
          isRequired: true,
          id: null,
          isVisible: true,
          categoryId: 1,
          name: nameFields,
          description: descriptionFields,
        },
        valueTranslations: valueTranslationsFields,
        attributeId: null,
        value: "",
        productId: null,
        variationId: null,
      },
    ]);
  };

  const handleDelete = (index) => {
    const updatedAttributes = [...attribute];
    updatedAttributes[index] = false;
    updatedAttributes.splice(index, 1);
    setAttribute(updatedAttributes);
  };

  const deleteAdditonAttribute = (id) => {
    setAttributeLoading((prev) => ({ ...prev, [id]: true }));

    mutate(id, {
      onSuccess: () => {
        const currentAttrs = getValues("attrs") || {};
        const updatedAttrs = { ...currentAttrs };
        delete updatedAttrs[id];

        setValue("attrs", updatedAttrs, { shouldValidate: true });

        setAttributeValues((prev) =>
          prev.filter((attr) => attr.attributeId !== id)
        );

        toast.success(t("Attribute_deleted_successfully"));
      },
      onError: (error) => {
        toast.error(error);
      },
      onSettled: () => {
        setAttributeLoading((prev) => ({ ...prev, [id]: false }));
      },
    });
  };
console.log(watch("saleType"));

  return (
    <div className="page-content ">
      <ToastContainer position="top-center" />
      <Col className="d-flex justify-content-center">
        <Card className="w-75">
          <CardBody>
            <div className="d-flex align-items-start gap-3">
              <i
                className="ri-arrow-left-line fs-3 cursor-pointer"
                onClick={() => navigate(-1)}
              ></i>
              <h2>{t("Editing a product")}</h2>
            </div>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex gap-3 flex-column">
                <div>
                  <Label className="fw-semibold">{t("Product status")}</Label>
                  <StateSelect id={id} data={data} />
                </div>
                <div>
                  <Label className="fw-semibold">{t("Visible")}</Label>
                  <Visibility id={id} data={data} />
                </div>
              </div>
              <div>
                <Label className="fw-semibold">{t("Product category")}</Label>
                <ReactInput
                  className="form-control border-dashed p-3"
                  value={data?.result?.category?.name}
                  readOnly
                />
              </div>
            </div>

            <Form
              onSubmit={handleSubmit(Submit)}
              className="d-flex flex-column gap-3 mt-3"
            >
              <div className="d-flex flex-column gap-3">
                {languages.map((langCode) => (
                  <div key={langCode}>
                    <Label style={{ fontSize: "15px" }}>
                      {langCode === "ru"
                        ? t("name_ru")
                        : langCode === "uz-Latn-UZ"
                        ? t("name_lat")
                        : langCode === "uz-Cyrl-UZ"
                        ? t("name_cyrl")
                        : t("name_en")}
                    </Label>
                    <Input
                      control={control}
                      errors={errors}
                      name={`name.${langCode}`}
                      inputProps={{
                        placeholder: t("Enter the title"),
                      }}
                    />
                    {errors.name && errors.name[langCode] && (
                      <div className="text-danger">
                        {t(errors.name[langCode].message)}
                      </div>
                    )}
                  </div>
                ))}

                {languages.map((langCode) => (
                  <div key={`description-${langCode}`}>
                    <Label style={{ fontSize: "15px" }}>
                      {langCode === "ru"
                        ? t("description_ru")
                        : langCode === "uz-Latn-UZ"
                        ? t("description_lat")
                        : langCode === "uz-Cyrl-UZ"
                        ? t("description_cyrl")
                        : t("description_en")}
                    </Label>
                    <Textarea
                      control={control}
                      errors={errors}
                      name={`description.${langCode}`}
                      inputProps={{
                        placeholder: t("Enter a description"),
                        type: "textarea",
                      }}
                    />
                    {errors.description && errors.description[langCode] && (
                      <div className="text-danger">
                        {t(errors.description[langCode].message)}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="d-flex gap-3 align-items-center mt-3">
                {priceType.map((type, index) => (
                  <div className="w-100" key={type}>
                    <Label>
                      {type === "Price"
                        ? t("Price")
                        : type === "Vat"
                        ? t("Including VAT")
                        : t("MSRP")}{" "}
                      <span className="text-danger">*</span>
                    </Label>

                    <Controller
                      control={control}
                      name={`prices[${index}].value`}
                      render={({ field }) => (
                        <ReactInput
                          {...field}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                        />
                      )}
                    />
                    {errors?.prices?.[index]?.value && (
                      <div className="text-danger">
                        {t(errors?.prices[index]?.value?.message)}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="d-flex justify-content-between gap-5 align-items-center w-100">
                <div className="mb-3 mt-2 w-50">
                  <Label htmlFor="sale-type">{t("Transaction type")}</Label>
                  <div className="row">
                    
                          {productSaleTypes.map((type) => (
                                                 <div   className={`col-lg-6 d-flex gap-2`} key={type}>
                                                 <ReactInput
                                                   type="checkbox"
                                                   id={`sale-type-${type}`}
                                                   value={type}
                                                   onChange={() => handleSaleTypeChange(type)}
                                                   checked={
                                                     Array.isArray(watch("saleType")) && watch("saleType").includes(type)
                                                   }
                                                   onClick={() => clearErrors("saleType")}
                                                 />
                                                 <Label htmlFor={`sale-type-${type}`}>{saleType[type]}</Label>
                                               </div>
                                            ))}
                  </div>
                  {errors.saleType && (
                    <div className="text-danger">
                      {t(errors.saleType.message)}
                    </div>
                  )}
                </div>

                <div className="w-50">
                  <Label>{t("brand")}</Label>
                  <Controller
                    name="brandId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          { value: 1, label: t("no_brand") },
                          ...(Array.isArray(brands?.item)
                            ? brands.item.map((item) => ({
                                value: item.id,
                                label: item.name,
                              }))
                            : []),
                        ]}
                      />
                    )}
                  />
                  {errors.brandId && (
                    <div className="text-danger">
                      {t(errors.brandId.message)}
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex gap-2 flex-wrap">
                {fileInputs.map((input, index) => (
                  <div
                    key={index}
                    style={{
                      width: "200px",
                      position: "relative",
                    }}
                    draggable={!!filePreviews[index]}
                    onDragStart={() =>
                      filePreviews[index] && handleDragStart(index)
                    }
                    onDragOver={
                      filePreviews[index] ? handleDragOver : undefined
                    }
                    onDrop={() => filePreviews[index] && handleDrop(index)}
                  >
                    <Dropzone
                      accept="image/jpeg, image/png, image/jpg,"
                      maxSize={maxFileSize}
                      disabled={!!filePreviews[index]}
                      onDropRejected={onDropRejected}
                      onDropAccepted={onDropAccepted}
                      onDrop={(acceptedFiles) =>
                        handleFileUpload(acceptedFiles)
                      }
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragReject,
                      }) => (
                        <div
                          {...getRootProps({ className: "dropzone" })}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            border: "2px dashed #cccccc",
                            borderRadius: "5px",
                            cursor: filePreviews[index] ? "move" : "pointer",
                            backgroundColor: isDragActive
                              ? "#e6f7ff"
                              : isDragReject
                              ? "#ffcccc"
                              : "white",
                          }}
                        >
                          {!filePreviews[index] && (
                            <div className="d-flex align-items-center justify-content-center">
                              <i className="bx bx-image-add display-4"></i>
                            </div>
                          )}
                          {filePreviews[index] && (
                            <img
                              src={filePreviews[index]}
                              alt={`Preview ${index}`}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "180px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                    {filePreviews[index] && (
                      <Button
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={() => handleDeleteImage(index)}
                        color="danger"
                      >
                        {t("Delete")}
                      </Button>
                    )}
                  </div>
                ))}
              </div>
              <div>{t("Recommended image size 1000x1000px")}</div>
              <div className="d-flex flex-column gap-1">
                {errors.files && (
                  <span className="text-danger">{t(errors.files.message)}</span>
                )}
                <span
                  ref={errorMessageRef}
                  style={{ color: "red", marginTop: "10px" }}
                ></span>
              </div>
              <div hidden={!attributes?.item?.some(item => item.isVisible)}>
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    onClick={toggleAttribute}
                    outline
                    className="me-2 border-0 d-flex justify-content-between align-items-start gap-1"
                    color="none"
                  >
                    {attributeShow ? t("Hide") : t("show")}
                    {attributeShow ? (
                      <i className="bx bx-chevron-down fs-4"></i>
                    ) : (
                      <i className="bx bx-chevron-up fs-4"></i>
                    )}
                  </Button>
                </div>
              </div>

              <div className="d-flex flex-column gap-3">
                {attributeShow &&
                  attributes?.item.sort((a,b) => a.weight - b.weight).map(
                    (item, idx) =>
                      item.isVisible && (
                        <div key={idx}>
                          {item.filter ? (
                            item.filter.filterType === "Range" ||
                            item.filter.filterType === "Price" ? (
                              <div>
                                <div className="d-flex flex-column ">
                                  <Label style={{ fontWeight: 550 }}>
                                    {item.name}
                                  </Label>
                                  <span style={{ color: "gray" }}>
                                    {item?.filter?.values}
                                  </span>
                                </div>
                                <Input
                                  control={control}
                                  name={`attrs.${item.id}.value`}
                                />
                              </div>
                            ) : item.filter.filterType === "SingleSelect" ? (
                              <div>
                                <Label style={{ fontWeight: 550 }}>
                                  {item.name}
                                </Label>
                               <div className="d-flex gap-2 flex-wrap align-items-center">
                               {item.filter.values
                                  .split(",")
                                  .map((value, valueIdx) => (
                                    <div key={valueIdx} className="form-check"
                                     style={{
                                      border: `2px solid ${
                                        watch(`attrs.${item.id}.value`) === value 
                                          ? "#13aff0"
                                          : "#ccc"
                                      }`,
                                      padding: "5px 10px 5px 25px",
                                      borderRadius: "25px",
                                      cursor: "pointer",
                                      backgroundColor:
                                      watch(`attrs.${item.id}.value`) ===  value 
                                          ? "#13aff0"
                                          : "white",
                                      transition: "all 0.3s ease",
                                      textAlign: "center",
                                    }}
                                    onClick={() => {
                                      setValue(
                                        `attrs.${item.id}.value`,
                                        value
                                      );
                                     
                                    }}
                                    >
                                      
                                      <ReactInput
                                        name={`attrs.${item.id}.value`}
                                        value={value}
                                        type={"radio"}
                                        checked={
                                          watch(`attrs.${item.id}.value`) ===
                                          value
                                        }
                                        onChange={() =>
                                          setValue(
                                            `attrs.${item.id}.value`,
                                            value
                                          )
                                        }
                                        className="form-check-input"
                                        id={value}
                                        style={{
                                          background:
                                          watch(`attrs.${item.id}.value`) ===   value 
                                              ? "#23b50d"
                                              : "transparent",
                                        }}
                                      />
                                      <Label
                                        htmlFor={value}
                                        className="form-check-label"
                                        style={{
                                          margin: "0",
                                          fontWeight: "bold",
                                          color:
                                          watch(`attrs.${item.id}.value`) ===   value 
                                              ? "#fff"
                                              : "#333",
                                        }}
                                      >
                                        {value === "0"
                                          ? "Yes"
                                          : value === "1"
                                          ? "No"
                                          : value}
                                      </Label>
                                    </div>
                                  ))}
                               </div>
                              </div>
                            ) : item.filter.filterType === "MultipleSelect" ? (
                              <div>
                                <Label style={{ fontWeight: 550 }}>
                                  {item.name}
                                </Label>
                                <div className="d-flex gap-2 align-items-center flex-wrap">
                                {item.filter.values
                                  .split(",")
                                  .map((value, valueIdx) => (
                                    <div key={valueIdx} className="form-check"
                                    style={{
                                      border: `2px solid ${
                                        watch(`attrs.${item.id}.value`)?.split(",")
                                          .includes(value.trim())
                                          ? "#13aff0"
                                          : "#ccc"
                                      }`,
                                      padding: "5px 10px 5px 25px",
                                      borderRadius: "25px",
                                      cursor: "pointer",
                                      backgroundColor: watch(
                                        `attrs.${item.id}.value`
                                      )?.split(",")
                                        .includes(value.trim())
                                        ? "#13aff0"
                                        : "white",
                                      transition: "all 0.3s ease",
                                      textAlign: "center",
                                    }}
                                  
                                    >
                                      <Controller
                                        control={control}
                                        name={`attrs.${item.id}.value`}
                                        render={({ field }) => {

                                          const selectedValues = field.value
                                            ? field.value.split(",")
                                            : [];
                                          const isChecked =
                                            selectedValues.includes(
                                              value.trim()
                                            );

                                          return (
                                            <>
                                            
                                              <ReactInput
                                                {...field}
                                                type="checkbox"
                                                value={value.trim()}
                                                checked={isChecked}
                                                onChange={(e) => {
                                                  const updatedValues = [
                                                    ...selectedValues,
                                                  ];
                                                  if (e.target.checked) {
                                                    updatedValues.push(
                                                      value.trim()
                                                    );
                                                  } else {
                                                    const index =
                                                      updatedValues.indexOf(
                                                        value.trim()
                                                      );
                                                    if (index > -1) {
                                                      updatedValues.splice(
                                                        index,
                                                        1
                                                      );
                                                    }
                                                  }
                                                  setValue(
                                                    `attrs.${item.id}.value`,
                                                    updatedValues.join(",")
                                                  );
                                                }}
                                                className="form-check-input"
                                                id={value.trim()}
                                                style={{
                                                  backgroundColor:
                                                    field.value?.split(",")
                                                      .includes(
                                                        value.trim()
                                                      )
                                                      ? "#23b50d"
                                                      : "transparent",
                                                  transition:
                                                    "background-color 0.3s ease",
                                                }}
                                              />
                                              <Label
                                                htmlFor={value.trim()}
                                                className="form-check-label"
                                                style={{
                                                  margin: "0",
                                                  fontWeight: "bold",
                                                  color: watch(
                                                    `attrs.${item.id}.value`
                                                  )?.split(",")
                                                    .includes(
                                                      value.trim()
                                                    )
                                                    ? "#fff"
                                                    : "#333",
                                                }}
                                              >
                                                {value}
                                              </Label>
                                            </>
                                          );
                                        }}
                                      />
                                    </div>
                                  ))}
                                </div>
                                {errors.attribute &&
                                  errors.attribute[idx] &&
                                  errors.attribute[idx].value && (
                                    <span className="text-danger">
                                      {errors.attribute[idx].value.message}
                                    </span>
                                  )}
                              </div>
                            ) : null
                          ) : !item.isValueTranslated ? (
                            <div>
                              <Label>{item.name}</Label>
                              <Input
                                control={control}
                                name={`attrs.${item.id}.value`}
                                errors={errors}
                              />
                              {errors.attribute &&
                                errors.attribute[item.id] &&
                                errors.attribute[item.id].value && (
                                  <span className="text-danger">
                                    {errors.attribute[item.id].value.message}
                                  </span>
                                )}
                            </div>
                          ) : (
                            item.isValueTranslated && (
                              <div>
                                <Label>{item.name}</Label>
                                <div className="d-flex gap-3">
                                  {languages.map((langCode) => (
                                    <div className="w-100" key={langCode}>
                                      <InputLabel
                                        control={control}
                                        errors={errors}
                                        name={`attrs.${item.id}.valueTranslations.${langCode}`}
                                        label={
                                          langCode === "ru"
                                            ? t("ru")
                                            : langCode === "uz-Latn-UZ"
                                            ? t("uz")
                                            : langCode === "uz-Cyrl-UZ"
                                            ? t("cryl")
                                            : t("en")
                                        }
                                      />
                                      {errors.attrs &&
                                        errors.attrs[idx] &&
                                        errors.attrs[idx]
                                          ?.valueTranslations && (
                                          <span className="text-danger">
                                            {t(
                                              errors.attrs[idx]
                                                ?.valueTranslations[langCode]
                                                ?.message
                                            )}
                                          </span>
                                        )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )
                  )}

                {attributeValues.map(
                  (item, index) =>
                    item.attribute.isAdditional && (
                      <div key={index}>
                        <div className="d-flex justify-content-end mb-3">
                          <Button
                            type="button"
                            onClick={() =>
                              deleteAdditonAttribute(item.attributeId)
                            }
                            color="danger"
                          >
                            {attributeLoading[item.attributeId] ? (
                              <Spinner size="sm" />
                            ) : (
                              t("Delete")
                            )}
                          </Button>
                        </div>

                        <div className="d-flex gap-3">
                          {languages.map((langCode) => (
                            <div className="w-100" key={langCode}>
                              <InputLabel
                                control={control}
                                name={`attrs.${item.attributeId}.attribute.name.${langCode}`}
                                errors={errors}
                                label={
                                  langCode === "ru"
                                    ? t("ru")
                                    : langCode === "uz-Latn-UZ"
                                    ? t("uz")
                                    : langCode === "uz-Cyrl-UZ"
                                    ? t("cryl")
                                    : t("en")
                                }
                                inputProps={{
                                  placeholder:
                                    langCode === "ru"
                                      ? t(`in russian`)
                                      : langCode === "uz-Latn-UZ"
                                      ? t("in uzbek(lat)")
                                      : langCode === "uz-Cyrl-UZ"
                                      ? t("in uzbek(kyr)")
                                      : t("in en"),
                                }}
                              />
                            </div>
                          ))}
                        </div>

                        <div className="d-flex gap-3">
                          {languages.map((langCode) => (
                            <div className="w-100" key={langCode}>
                              <InputLabel
                                control={control}
                                name={`attrs.${item.attributeId}.valueTranslations.${langCode}`}
                                errors={errors}
                                label={
                                  langCode === "ru"
                                    ? t("ru")
                                    : langCode === "uz-Latn-UZ"
                                    ? t("uz")
                                    : langCode === "uz-Cyrl-UZ"
                                    ? t("cryl")
                                    : t("en")
                                }
                                inputProps={{
                                  placeholder:
                                    langCode === "ru"
                                      ? t(`Значение`)
                                      : langCode === "uz-Latn-UZ"
                                      ? t("Значение лат")
                                      : langCode === "uz-Cyrl-UZ"
                                      ? t("Значение кир")
                                      : t("Значение анг"),
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                )}

                {attribute.map((attr, index) => (
                  <div key={index}>
                    <div className="d-flex justify-content-end mb-3">
                      <Button
                        type="button"
                        onClick={() => handleDelete(index)}
                        color="danger"
                      >
                        {t("Delete")}
                      </Button>
                    </div>

                    <div className="d-flex gap-3">
                      {languages.map((langCode) => (
                        <div className="w-100" key={langCode}>
                          <InputLabel
                            errors={errors}
                            label={
                              langCode === "ru"
                                ? t("ru")
                                : langCode === "uz-Latn-UZ"
                                ? t("uz")
                                : langCode === "uz-Cyrl-UZ"
                                ? t("cryl")
                                : t("en")
                            }
                            inputProps={{
                              placeholder:
                                langCode === "ru"
                                  ? t(`in russian`)
                                  : langCode === "uz-Latn-UZ"
                                  ? t("in uzbek(lat)")
                                  : langCode === "uz-Cyrl-UZ"
                                  ? t("in uzbek(kyr)")
                                  : t("in en"),
                              value: attr.attribute.name[langCode] || "",
                              onChange: (e) => {
                                const payload = [...attribute];
                                payload[index].attribute.name[langCode] =
                                  e.target.value;
                                setAttribute(payload);
                              },
                            }}
                          />
                        </div>
                      ))}
                    </div>

                    <div className="d-flex gap-3">
                      {languages.map((langCode) => (
                        <div className="w-100" key={langCode}>
                          <InputLabel
                            errors={errors}
                            label={
                              langCode === "ru"
                                ? t("ru")
                                : langCode === "uz-Latn-UZ"
                                ? t("uz")
                                : langCode === "uz-Cyrl-UZ"
                                ? t("cryl")
                                : t("en")
                            }
                            inputProps={{
                              placeholder:
                                langCode === "ru"
                                  ? t(`Значение`)
                                  : langCode === "uz-Latn-UZ"
                                  ? t("Значение лат")
                                  : langCode === "uz-Cyrl-UZ"
                                  ? t("Значение кир")
                                  : t("Значение анг"),
                              value: attr.valueTranslations[langCode] || "",
                              onChange: (e) => {
                                const payload = [...attribute];
                                payload[index].valueTranslations[langCode] =
                                  e.target.value;
                                setAttribute(payload);
                              },
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-end mt-3 mb-2 gap-2">
                <Button
                  onClick={addAttribute}
                  type="button"
                  className="d-flex align-items-center gap-2"
                >
                  {t("add_attribute")}
                </Button>
                <Button
                  className="btn btn-primary d-flex align-items-center gap-2  "
                  type="submit"
                >
                  {loading ? (
                    <Spinner size="sm" className="me-2">
                      Loading...
                    </Spinner>
                  ) : null}
                  {t("Save")}
                  <i className="ri-check-line"></i>
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default ProductsEdit;

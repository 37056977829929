import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Spinner,
  TabContent,
  TabPane,
  Input as ReactInput,
} from "reactstrap";
import { useFieldArray } from "react-hook-form";
import useHookForm from "../../../hooks/useHookForm";
import { ProductsPost, getAttributesId, uploadFiles } from "../../../api";
import { Input } from "../../../Components/atoms/input";
import createSchema from "../../../helpers/createSchema";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBrandsQuery,
  getCategoriesQuery,
  getOrganizationQuery,
  getProfileQuery,
} from "../../../queries";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import AddProductForm from "./Components/add-product-form";
import { Textarea } from "../../../Components/atoms/textarea";
import { InputLabel } from "../../../Components/atoms/inputLabel";
import { config } from "../../../utils/config";
const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  categoryId: null,
  attrs: [],
  prices: [
    { type: "Price", value: "" },
    { type: "Vat", value: "" },
    { type: "Sale", value: "" },
  ],
  brandId: null,
  files: [],
  saleType: [],
};
const saleType = {
  1: "Рассрочка",
  2: "Компенсация",
  4: "Продажа",
  8: "ООН",
};

const AddProducts = () => {
  const schema = createSchema({
    attrs: "attrs",
    name: "name2",
    description: "description2",
    saleType: "saleType",
    files: "files",
    // brandId: "select",
  });

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useHookForm(values, schema);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [fileInputs, setFileInputs] = useState([0]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [attribute, setAttribute] = useState([]);
  const [attributeShow, setAttributeShow] = useState(true);
  const errorMessageRef = useRef(null);
  const client = useQueryClient();
  const navigate = useNavigate();
  const maxFileSize = 10 * 1024 * 1024;
  const toggleAttribute = () => {
    setAttributeShow(!attributeShow);
  };

  const { data: profile } = useQuery({
    ...getProfileQuery(),
  });
  const { data: brands } = useQuery({
    ...getBrandsQuery({ isAll: true }),
  });
  const { data: organization } = useQuery({
    ...getOrganizationQuery(profile?.result?.organizationId),
    enabled: !!profile,
  });
  const getFilterSaleType = (productSaleType) => {
    return Object.keys(saleType)
      .map(Number)
      .filter((type) => productSaleType & type);
  };

  const productSaleTypes = getFilterSaleType(
    organization?.result?.productSaleTypes
  );
  const handleFileUpload = (acceptedFiles) => {
    let newImageFiles = [...imageFiles];
    let newPreviews = [...filePreviews];
    let newFileInputs = [...fileInputs];

    acceptedFiles.forEach((file, index) => {
      const currentIndex = imageFiles.length + index;
      newImageFiles[currentIndex] = file;
      newPreviews[currentIndex] = URL.createObjectURL(file);
      newFileInputs.push(newFileInputs.length);
    });

    setImageFiles(newImageFiles);
    setFilePreviews(newPreviews);
    setFileInputs(newFileInputs);

    setValue("files", newImageFiles, { shouldValidate: true });
  };
  // useEffect(() => {
  //   if (errors?.attrs) {
  //     setAttributeShow(true);
  //   }
  // }, [errors]);
  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (index) => {
    if (draggedIndex === null) return;

    const updatedImageFiles = [...imageFiles];
    const updatedPreviews = [...filePreviews];

    [updatedImageFiles[draggedIndex], updatedImageFiles[index]] = [
      updatedImageFiles[index],
      updatedImageFiles[draggedIndex],
    ];

    [updatedPreviews[draggedIndex], updatedPreviews[index]] = [
      updatedPreviews[index],
      updatedPreviews[draggedIndex],
    ];

    setImageFiles(updatedImageFiles);
    setFilePreviews(updatedPreviews);
    setDraggedIndex(null);
  };

  const handleDeleteImage = (index) => {
    const currentFiles = [...watch("files")];
    const deletedFile = currentFiles[index];
    setDeletedFiles((prev) => [...prev, deletedFile]);
    currentFiles.splice(index, 1);
    setValue("files", currentFiles);
    const updatedImageFiles = [...imageFiles];
    updatedImageFiles.splice(index, 1);
    setImageFiles(updatedImageFiles);
    const updatedPreviews = [...filePreviews];
    updatedPreviews.splice(index, 1);
    setFilePreviews(updatedPreviews);
    const updatedInputs = [...fileInputs];
    updatedInputs.splice(index, 1);
    setFileInputs(updatedInputs);
  };

  const onDropAccepted = (acceptedFiles) => {
    if (errorMessageRef.current) {
      errorMessageRef.current.textContent = "";
    }
    handleFileUpload(acceptedFiles);
  };

  const onDropRejected = (rejectedFiles) => {
    const hasFileTooLarge = rejectedFiles.some((file) =>
      file.errors.some((error) => error.code === "file-too-large")
    );

    if (hasFileTooLarge) {
      if (errorMessageRef.current) {
        const fileSizeInMB = (
          rejectedFiles[0].file.size /
          (1024 * 1024)
        ).toFixed(2);
        errorMessageRef.current.textContent =
          t("File size must not exceed 10 MB!") +
          " " +
          `File size ${fileSizeInMB} MB`;
      }
    }
  };

  const getProductSaleTypeNames = (productSaleTypes) => {
    const types = [];
    if (productSaleTypes & 1) types.push(t("Compensation"));
    if (productSaleTypes & 2) types.push(t("Installment"));
    if (productSaleTypes & 4) types.push(t("Sale"));
    if (productSaleTypes & 8) types.push(t("UN"));
    return types.join(", ");
  };
  const handleSaleTypeChange = (type) => {
    const saleTypeValues = watch("saleType") || [];
    const updatedSaleType = saleTypeValues.includes(type)
      ? saleTypeValues.filter((t) => t !== type)
      : [...saleTypeValues, type];
    setValue("saleType", updatedSaleType);
  };

  const handleMultipleSelectChange = (field, value, e) => {
    let newValue = field.value.split(",").filter(Boolean);
    if (e.target.checked) {
      newValue.push(value.trim());
    } else {
      newValue = newValue.filter((v) => v !== value.trim());
    }
    field.onChange(newValue.join(","));
  };

  const calculateSaleType = () => {
    return watch("saleType").reduce((acc, type) => acc + type, 0);
  };

  const Submit = async () => {
    setLoading(true);
    const attributeId = watch("categoryId")?.value;
    try {
      const res = await getAttributesId(attributeId, {
        page: 1,
        size: 2147483647,
      });
      const arr = res.item.map((item) => ({
        ...item,
        attributeId: item.id,
        value: "",
        valueTranslations: {
          ru: "",
          "uz-Latn-UZ": "",
          "uz-Cyrl-UZ": "",
        },
      }));
      setValue("attrs", arr);
      client.invalidateQueries({ queryKey: ["products"] });
      setLoading(true) ? setShow(false) : setShow(true);
    } catch (error) {
      alert(error?.data?.errors?.errorMessage);
    } finally {
      setLoading(false);
    }
  };

  let languages = ["ru", "uz-Latn-UZ", "uz-Cyrl-UZ"];
  let priceType = ["Price", "Vat", "Sale"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }

  const ProductSubmit = async (data) => {
    if (!data?.prices?.[0].value) {
      return setError("prices[0].value", {
        type: "manual",
        message: t("required_field_error"),
      });
    }

    if (
      data.prices[2].value !== "" &&
      +data.prices[0].value > +data.prices[2].value
    ) {
      return setError("prices[2].value", {
        type: "manual",
        message: t("price_2_error"),
      });
    }

    setProductLoading(true);

    let index = 1;
    const uploadedFiles = [];
    const filteredImageFiles = imageFiles.filter(
      (file) => !deletedFiles.includes(file)
    );

    for (const file of filteredImageFiles) {
      const formData = new FormData();
      formData.append("files", file);

      const resImage = await uploadFiles(formData);
      const imageObj = resImage?.[0];

      uploadedFiles.push({
        order: index,
        url: imageObj?.url,
        fileId: imageObj?.id,
        variationId: null,
        productId: null,
      });

      index++;
    }

    const names = languages.map((languageCode) => ({
      languageCode,
      text: watch(`name.${languageCode}`),
    }));

    const descriptions = languages.map((languageCode) => ({
      languageCode,
      text: watch(`description.${languageCode}`),
    }));

    const customAttributes = attribute.map((attr) => ({
      attributeId: attr.attributeId || null,
      value: attr.value || "",
      productId: null,
      valueTranslations: languages.map((languageCode) => ({
        languageCode,
        text: attr.valueTranslations?.[languageCode] || "",
      })),
      variationId: null,
      attribute: {
        weight: 0,
        dataType: "Text",
        hasFilter: false,
        isValueTranslated: true,
        isAdditional: true,
        isRequired: true,
        name: languages.map((languageCode) => ({
          languageCode,
          text: attr.attribute?.name?.[languageCode] || "",
        })),
        description: languages.map((languageCode) => ({
          languageCode,
          text: attr.attribute?.description?.[languageCode] || "",
        })),
        id: null,
        isVisible: true,
        categoryId: 43,
      },
    }));

    const variations = [
      {
        count: 1000,
        compensationOnly: false,
        productId: null,
        attributeValues: [
          ...(customAttributes || []),
          ...data.attrs.map((attr) => ({
            attributeId: attr.attributeId || null,
            value: attr.value || "",
            productId: null,
            valueTranslations: languages.map((languageCode) => ({
              languageCode,
              text: attr.valueTranslations?.[languageCode] || "",
            })),
            variationId: null,
            attribute: null,
          })),
        ],
        prices: data?.prices.map((price) => ({
          currencyId: 1,
          value: price.value === "" ? 0 : +price.value,
          variationId: null,
          type: price.type,
        })),
        files: uploadedFiles,
        saleType: calculateSaleType(),
      },
    ];

    const attributeId = data?.categoryId?.value;
    const datas = {
      name: names,
      description: descriptions,
      brandId: !watch("brandId")?.value ? 1 : data.brandId?.value,
      categoryId: attributeId,
      organizationId: profile?.result?.organizationId,
      variations: variations,
    };

    try {
      await ProductsPost(datas);
      client.invalidateQueries({ queryKey: ["moderations"] });
      navigate("/products-moderate");
    } catch (error) {
      alert(error?.data?.errors?.errorMessage);
    } finally {
      setProductLoading(false);
    }
  };

  const { fields } = useFieldArray({
    control,
    name: "attrs",
  });

  const addAttribute = () => {
    const nameFields =
      config.SITENAME === "food"
        ? {
            ru: "",
            en: "",
            "uz-Latn-UZ": "",
          }
        : {
            ru: "",
            "uz-Latn-UZ": "",
            "uz-Cyrl-UZ": "",
          };

    const descriptionFields =
      config.SITENAME === "food"
        ? {
            ru: "",
            en: "",
            "uz-Latn-UZ": "",
          }
        : {
            ru: "",
            "uz-Latn-UZ": "",
            "uz-Cyrl-UZ": "",
          };

    const valueTranslationsFields =
      config.SITENAME === "food"
        ? {
            ru: "",
            en: "",
            "uz-Latn-UZ": "",
          }
        : {
            ru: "",
            "uz-Latn-UZ": "",
            "uz-Cyrl-UZ": "",
          };
    setAttribute([
      ...attribute,
      {
        attribute: {
          weight: 0,
          dataType: "Text",
          hasFilter: false,
          isValueTranslated: true,
          isAdditional: true,
          isRequired: true,
          id: null,
          isVisible: true,
          categoryId: 1,
          name: nameFields,
          description: descriptionFields,
        },
        valueTranslations: valueTranslationsFields,
        attributeId: null,
        value: "",
        productId: null,
        variationId: null,
      },
    ]);
  };

  const handleDelete = (index) => {
    const updatedAttributes = [...attribute];
    updatedAttributes[index] = false;
    updatedAttributes.splice(index, 1);
    setAttribute(updatedAttributes);
  };
  console.log(errors);
  const [selectedValue, setSelectedValue] = useState("4");

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <div className="page-content">
      <Col className="d-flex justify-content-center">
        <Card className="w-75">
          <CardHeader>
            <div className="d-flex align-items-start gap-3">
              <i
                className="ri-arrow-left-line fs-3 cursor-pointer"
                onClick={() => navigate(-1)}
              ></i>
              <h2>{t("Add product")}</h2>
            </div>
          </CardHeader>
          <CardBody>
            <AddProductForm
              loading={loading}
              Submit={Submit}
              setValue={setValue}
              setShow={setShow}
              show={reset}
              attribute={() => setAttribute([])}
              preview={() => {
                setFilePreviews([]);
                setImageFiles([]);
                setFileInputs([0]);
              }}
            />

            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : (
              show && (
                <Form>
                  <div className="d-flex flex-column gap-3">
                    {languages.map((langCode) => (
                      <div key={langCode}>
                        <Label style={{ fontSize: "15px" }}>
                          {langCode === "ru"
                            ? t("name_ru")
                            : langCode === "uz-Latn-UZ"
                            ? t("name_lat")
                            : langCode === "uz-Cyrl-UZ"
                            ? t("name_cyrl")
                            : t("name_en")}{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          control={control}
                          errors={errors}
                          name={`name.${langCode}`}
                          inputProps={{
                            placeholder: t("Enter the title"),
                          }}
                        />
                        {errors.name && errors.name[langCode] && (
                          <div className="text-danger">
                            {t(errors.name[langCode].message)}
                          </div>
                        )}
                      </div>
                    ))}

                    {languages.map((langCode) => (
                      <div key={`description-${langCode}`}>
                        <Label style={{ fontSize: "15px" }}>
                          {langCode === "ru"
                            ? t("description_ru")
                            : langCode === "uz-Latn-UZ"
                            ? t("description_lat")
                            : langCode === "uz-Cyrl-UZ"
                            ? t("description_cyrl")
                            : t("description_en")}{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Textarea
                          control={control}
                          errors={errors}
                          name={`description.${langCode}`}
                          inputProps={{
                            placeholder: t("Enter a description"),
                            type: "textarea",
                          }}
                        />
                        {errors.description && errors.description[langCode] && (
                          <div className="text-danger">
                            {t(errors.description[langCode].message)}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="d-flex gap-3 align-items-center mt-3">
                    {priceType.map((type, index) => (
                      <div className="w-100" key={type}>
                        <Label>
                          {type === "Price"
                            ? t("Price")
                            : type === "Vat"
                            ? t("Including VAT")
                            : t("MSRP")}{" "}
                          {type === "Price" ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </Label>

                        <Controller
                          control={control}
                          name={`prices[${index}].value`}
                          render={({ field }) => (
                            <ReactInput
                              {...field}
                              type="number"
                              onWheel={(e) => e.target.blur()}
                            />
                          )}
                        />
                        {errors?.prices?.[index]?.value && (
                          <div className="text-danger">
                            {t(errors?.prices[index]?.value?.message)}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="d-flex justify-content-between gap-5 align-items-center w-100">
                    <div className="mb-3 mt-2 w-50">
                      <Label htmlFor="sale-type">
                        {t("Transaction type")}{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <div className="row">
                     
                        {productSaleTypes.map((type) => (
                          <div
                            className={`col-lg-6 d-flex gap-2`}
                            key={type}
                          >
                            <ReactInput
                              type="checkbox"
                              id={`sale-type-${type}`}
                              value={type}
                              onChange={() => handleSaleTypeChange(type)}
                              checked={watch("saleType").includes(type)}
                              onClick={() => clearErrors("saleType")}
                            />
                            <Label htmlFor={`sale-type-${type}`}>
                              {saleType[type]}{" "}
                            </Label>
                          </div>
                        ))}
                      </div>
                      {errors.saleType && (
                        <div className="text-danger">
                          {t(errors.saleType.message)}
                        </div>
                      )}
                    </div>
                    <div className="w-50">
                      <Label>{t("brand")}</Label>
                      <Controller
                        name="brandId"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={[
                              ...(Array.isArray(brands?.item)
                                ? brands.item.map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                  }))
                                : []),
                            ]}
                          />
                        )}
                      />
                      {errors.brandId && (
                        <div className="text-danger">
                          {t(errors.brandId.message)}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="d-flex gap-2 flex-wrap">
                    {fileInputs.map((input, index) => (
                      <div
                        key={index}
                        style={{
                          width: "200px",
                          position: "relative",
                        }}
                        draggable={!!filePreviews[index]}
                        onDragStart={() =>
                          filePreviews[index] && handleDragStart(index)
                        }
                        onDragOver={
                          filePreviews[index] ? handleDragOver : undefined
                        }
                        onDrop={() => filePreviews[index] && handleDrop(index)}
                      >
                        <Dropzone
                          accept="image/jpeg, image/png, image/jpg,"
                          maxSize={maxFileSize}
                          disabled={!!filePreviews[index]}
                          onDropRejected={onDropRejected}
                          onDropAccepted={onDropAccepted}
                          onDrop={(acceptedFiles) =>
                            handleFileUpload(acceptedFiles)
                          }
                        >
                          {({
                            getRootProps,
                            getInputProps,
                            isDragActive,
                            isDragReject,
                          }) => (
                            <div
                              {...getRootProps({ className: "dropzone" })}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                border: "2px dashed #cccccc",
                                borderRadius: "5px",
                                cursor: filePreviews[index]
                                  ? "move"
                                  : "pointer",
                                backgroundColor: isDragActive
                                  ? "#e6f7ff"
                                  : isDragReject
                                  ? "#ffcccc"
                                  : "white",
                              }}
                            >
                              {!filePreviews[index] && (
                                <div className="d-flex align-items-center justify-content-center">
                                  <i className="bx bx-image-add display-4"></i>
                                </div>
                              )}
                              {filePreviews[index] && (
                                <img
                                  src={filePreviews[index]}
                                  alt={`Preview ${index}`}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "180px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </Dropzone>
                        {filePreviews[index] && (
                          <Button
                            style={{ position: "absolute", top: 0, right: 0 }}
                            onClick={() => handleDeleteImage(index)}
                            color="danger"
                          >
                            {t("Delete")}
                          </Button>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="mt-2">
                    {t("Recommended image size 1000x1000px")}
                  </div>

                  <div className="d-flex flex-column gap-1">
                    {errors.files && (
                      <span className="text-danger">
                        {t(errors.files.message)}
                      </span>
                    )}
                    <span
                      ref={errorMessageRef}
                      style={{ color: "red", marginTop: "10px" }}
                    ></span>
                  </div>

                  {/* <div hidden={!fields.some((item) => item.isVisible)}>
                    <div className="d-flex justify-content-end align-items-center">
                      <Button
                        onClick={toggleAttribute}
                        outline
                        className="me-2 border-0 d-flex justify-content-between align-items-start gap-1"
                        color="none"
                      >
                        {attributeShow ? t("Hide") : t("show")}
                        {attributeShow ? (
                          <i className="bx bx-chevron-down fs-4"></i>
                        ) : (
                          <i className="bx bx-chevron-up fs-4"></i>
                        )}
                      </Button>
                    </div>
                  </div> */}
                  <div className="d-flex flex-column gap-3">
                    {fields
                      .sort((a, b) => a.weight - b.weight)
                      .map(
                        (item, idx) =>
                          item.isVisible && (
                            <div key={idx}>
                              {item.filter ? (
                                item.filter.filterType === "Range" ||
                                item.filter.filterType === "Price" ? (
                                  <div>
                                    <div className="d-flex flex-column">
                                      <Label style={{ fontWeight: 550 }}>
                                        {item.name}{" "}
                                        {item.isRequired ? (
                                          <span className="text-danger">*</span>
                                        ) : (
                                          ""
                                        )}
                                      </Label>
                                      <span style={{ color: "gray" }}>
                                        {item?.filter?.values}
                                      </span>
                                    </div>
                                    <Controller
                                      control={control}
                                      name={`attrs[${idx}].value`}
                                      render={({ field }) => (
                                        <ReactInput
                                          {...field}
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                        />
                                      )}
                                    />
                                    {errors.attrs &&
                                      errors.attrs[idx] &&
                                      errors.attrs[idx].value && (
                                        <span className="text-danger">
                                          {t(errors.attrs[idx].value.message)}
                                        </span>
                                      )}
                                  </div>
                                ) : item.filter.filterType ===
                                  "SingleSelect" ? (
                                  // <div>
                                  //   <Label style={{ fontWeight: 550 }}>
                                  //     {item.name}{" "}
                                  //     {item.isRequired && (
                                  //       <span className="text-danger">*</span>
                                  //     )}
                                  //   </Label>
                                  //   {item.filter.values
                                  //     .split(",")
                                  //     .map((value, valueIdx) => (
                                  //       <div
                                  //         key={valueIdx}
                                  //         className="form-check"
                                  //       >
                                  //         <Controller
                                  //           control={control}
                                  //           name={`attrs[${idx}].value`}
                                  //           render={({ field }) => (
                                  //             <ReactInput
                                  //               {...field}
                                  //               checked={field.value === value.trim()}
                                  //               value={value.trim()}
                                  //               type={"radio"}
                                  //               id={value}
                                  //             />
                                  //           )}
                                  //         />
                                  //         <Label
                                  //           htmlFor={value}
                                  //           className="form-check-label"
                                  //         >
                                  //           {value === "0"
                                  //             ? t("Yes")
                                  //             : value === "1"
                                  //             ? t("No")
                                  //             : value}
                                  //         </Label>
                                  //       </div>
                                  //     ))}
                                  //   {errors.attrs &&
                                  //     errors.attrs[idx] &&
                                  //     errors.attrs[idx].value && (
                                  //       <span className="text-danger">
                                  //         {t(errors.attrs[idx].value.message)}
                                  //       </span>
                                  //     )}
                                  // </div>
                                  <div>
                                    <Label style={{ fontWeight: 550 }}>
                                      {item.name}{" "}
                                      {item.isRequired && (
                                        <span className="text-danger">*</span>
                                      )}
                                    </Label>
                                    <div className="d-flex flex-wrap gap-3 mt-2">
                                      {item.filter.values
                                        .split(",")
                                        .map((value, valueIdx) => (
                                          <div
                                            key={valueIdx}
                                            className="form-check"
                                            style={{
                                              border: `2px solid ${
                                                watch(`attrs[${idx}].value`) ===
                                                value.trim()
                                                  ? "#7ac3f0"
                                                  : "#ccc"
                                              }`,
                                              padding: "5px 25px",
                                              borderRadius: "25px",
                                              cursor: "pointer",
                                              backgroundColor:
                                                watch(`attrs[${idx}].value`) ===
                                                value.trim()
                                                  ? "#7ac3f0"
                                                  : "white",
                                              transition: "all 0.3s ease",
                                              textAlign: "center",
                                            }}
                                            onClick={() => {
                                              setValue(
                                                `attrs[${idx}].value`,
                                                value.trim()
                                              );
                                              clearErrors(
                                                `attrs[${idx}].value`
                                              );
                                            }}
                                          >
                                            <Controller
                                              control={control}
                                              name={`attrs[${idx}].value`}
                                              render={({ field }) => (
                                                console.log(field),
                                                (
                                                  <ReactInput
                                                    {...field}
                                                    checked={
                                                      field.value ===
                                                      value.trim()
                                                    }
                                                    value={value.trim()}
                                                    type={"radio"}
                                                    id={value}
                                                    style={{
                                                      background:
                                                        field.value ===
                                                        value.trim()
                                                          ? "#23b50d"
                                                          : "transparent",
                                                    }}
                                                  />
                                                )
                                              )}
                                            />
                                            <Label
                                              htmlFor={value}
                                              className="form-check-label"
                                              style={{
                                                margin: "0",
                                                fontWeight: "bold",
                                                color:
                                                  watch(
                                                    `attrs[${idx}].value`
                                                  ) === value.trim()
                                                    ? "#fff"
                                                    : "#333",
                                              }}
                                            >
                                              {value === "0"
                                                ? t("Yes")
                                                : value === "1"
                                                ? t("No")
                                                : value}
                                            </Label>
                                          </div>
                                        ))}
                                    </div>
                                    {errors.attrs &&
                                      errors.attrs[idx] &&
                                      errors.attrs[idx].value && (
                                        <span className="text-danger">
                                          {t(errors.attrs[idx].value.message)}
                                        </span>
                                      )}
                                  </div>
                                ) : item.filter.filterType ===
                                  "MultipleSelect" ? (
                                  <div>
                                    <Label style={{ fontWeight: 550 }}>
                                      {item.name}{" "}
                                      {item.isRequired ? (
                                        <span className="text-danger">*</span>
                                      ) : (
                                        ""
                                      )}
                                    </Label>
                                    {/* {item.filter.values
                                      .split(",")
                                      .map((value, valueIdx) => (
                                        <div
                                          key={valueIdx}
                                          className="form-check"
                                          style={{
                                            border: `2px solid ${
                                              watch(`attrs[${idx}].value`) === value.trim()
                                                ? "#7ac3f0"
                                                : "#ccc"
                                            }`,
                                            padding: "5px 25px",
                                            borderRadius: "25px",
                                            cursor: "pointer",
                                            backgroundColor:
                                              watch(`attrs[${idx}].value`) === value.trim() ? "#7ac3f0" : "white", 
                                            transition: "all 0.3s ease",
                                            textAlign: "center",
                                          }}
                                          onClick={() => {
                                            setValue(
                                              `attrs[${idx}].value`,
                                              value.trim()
                                            );
                                            clearErrors(
                                              `attrs[${idx}].value`
                                            );
                                          }}
                                        >
                                          {console.log(value.trim(), valueIdx)}
                                          <Controller
                                            control={control}
                                            name={`attrs[${idx}].value`}
                                            render={({ field }) => (
                                              console.log(field),
                                              (
                                                <ReactInput
                                                  {...field}
                                                  value={value.trim()}
                                                  type="checkbox"
                                                  checked={field.value
                                                    .split(",")
                                                    .includes(value.trim())}
                                                  onChange={(e) =>
                                                    handleMultipleSelectChange(
                                                      field,
                                                      value,
                                                      e
                                                    )
                                                  }
                                                  style={{
                                                    backgroundColor:
                                                      field.value.split(",").includes(value.trim()) ? "#23b50d" : "transparent",
                                                    transition: "background-color 0.3s ease",
                                                  }}
                                                  id={value.trim()}
                                                />
                                              )
                                            )}
                                          />
                                          <Label
                                            htmlFor={value.trim()}
                                            className="form-check-label"
                                            style={{
                                              margin: "0",
                                              fontWeight: "bold",
                                              color:
                                                watch(`attrs[${idx}].value`) === value.trim()
                                                  ? "#fff" 
                                                  : "#333",
                                            }}
                                          >
                                            {value}
                                          </Label>
                                        </div>
                                      ))}
                                    {errors.attrs &&
                                      errors.attrs[idx] &&
                                      errors.attrs[idx].value && (
                                        <span className="text-danger">
                                          {t(errors.attrs[idx].value.message)}
                                        </span>
                                      )} */}
                                    <div className="d-flex gap-2 align-items-center flex-wrap">
                                      {item.filter.values
                                        .split(",")
                                        .map((value, valueIdx) => (
                                          <div
                                            key={valueIdx}
                                            className="form-check"
                                            style={{
                                              border: `2px solid ${
                                                watch(`attrs[${idx}].value`)
                                                  .split(",")
                                                  .includes(value.trim())
                                                  ? "#7ac3f0"
                                                  : "#ccc"
                                              }`,
                                              padding: "5px 25px",
                                              borderRadius: "25px",
                                              cursor: "pointer",
                                              backgroundColor: watch(
                                                `attrs[${idx}].value`
                                              )
                                                .split(",")
                                                .includes(value.trim())
                                                ? "#7ac3f0"
                                                : "white",
                                              transition: "all 0.3s ease",
                                              textAlign: "center",
                                            }}
                                          
                                          >
                                        
                                            <Controller
                                              control={control}
                                              name={`attrs[${idx}].value`}
                                              render={({ field }) => (
                                                console.log(field),
                                                (
                                                  <>
                                                    <ReactInput
                                                      {...field}
                                                      value={value.trim()}
                                                      type="checkbox"
                                                      checked={field.value
                                                        .split(",")
                                                        .includes(value.trim())}
                                                      onChange={(e) =>
                                                        handleMultipleSelectChange(
                                                          field,
                                                          value.trim(),
                                                          e
                                                        )
                                                      }
                                                      id={value.trim()}
                                                      style={{
                                                        backgroundColor:
                                                          field.value
                                                            .split(",")
                                                            .includes(
                                                              value.trim()
                                                            )
                                                            ? "#23b50d"
                                                            : "transparent",
                                                        transition:
                                                          "background-color 0.3s ease",
                                                      }}
                                                    />
                                                    <Label
                                                      htmlFor={value.trim()}
                                                      className="form-check-label"
                                                      style={{
                                                        margin: "0",
                                                        fontWeight: "bold",
                                                        color: watch(
                                                          `attrs[${idx}].value`
                                                        )
                                                          .split(",")
                                                          .includes(
                                                            value.trim()
                                                          )
                                                          ? "#fff"
                                                          : "#333",
                                                      }}
                                                    >
                                                      {value}
                                                    </Label>
                                                  </>
                                                )
                                              )}
                                            />
                                          </div>
                                        ))}
                                    </div>
                                    {errors.attrs &&
                                      errors.attrs[idx] &&
                                      errors.attrs[idx].value && (
                                        <span className="text-danger">
                                          {t(errors.attrs[idx].value.message)}
                                        </span>
                                      )}
                                  </div>
                                ) : null
                              ) : !item.isValueTranslated ? (
                                <div>
                                  <Label>
                                    {item.name}{" "}
                                    {item.isRequired ? (
                                      <span className="text-danger">*</span>
                                    ) : (
                                      ""
                                    )}
                                  </Label>
                                  <Controller
                                    control={control}
                                    name={`attrs[${idx}].value`}
                                    render={({ field }) => (
                                      <ReactInput
                                        type={
                                          item.dataType === "Float" ||
                                          item.dataType === "Integer" ||
                                          item.dataType === "Number"
                                            ? "number"
                                            : "text"
                                        }
                                        onWheel={(e) => e.target.blur()}
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors.attrs &&
                                    errors.attrs[idx] &&
                                    errors.attrs[idx].value && (
                                      <span className="text-danger">
                                        {t(errors.attrs[idx].value.message)}
                                      </span>
                                    )}
                                </div>
                              ) : (
                                item.isValueTranslated && (
                                  <div>
                                    <Label>
                                      {item.name}{" "}
                                      {item.isRequired ? (
                                        <span className="text-danger">*</span>
                                      ) : (
                                        ""
                                      )}
                                    </Label>

                                    <div className="d-flex gap-3">
                                      {languages.map((langCode) => (
                                        <div className="w-100" key={langCode}>
                                          <InputLabel
                                            control={control}
                                            errors={errors}
                                            name={`attrs[${idx}].valueTranslations.${langCode}`}
                                            label={
                                              langCode === "ru"
                                                ? t("ru")
                                                : langCode === "uz-Latn-UZ"
                                                ? t("uz")
                                                : langCode === "uz-Cyrl-UZ"
                                                ? t("cryl")
                                                : t("en")
                                            }
                                          />
                                          {errors.attrs &&
                                            errors.attrs[idx] &&
                                            errors.attrs[idx]
                                              ?.valueTranslations && (
                                              <span className="text-danger">
                                                {t(
                                                  errors.attrs[idx]
                                                    ?.valueTranslations[
                                                    langCode
                                                  ]?.message
                                                )}
                                              </span>
                                            )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )
                      )}
                    {attribute.map((attr, index) => (
                      <div key={index}>
                        <div className="d-flex justify-content-end mb-3">
                          <Button
                            type="button"
                            onClick={() => handleDelete(index)}
                            color="danger"
                          >
                            {t("Delete")}
                          </Button>
                        </div>

                        <div className="d-flex gap-3">
                          {languages.map((langCode) => (
                            <div className="w-100" key={langCode}>
                              <InputLabel
                                errors={errors}
                                label={
                                  langCode === "ru"
                                    ? t("ru")
                                    : langCode === "uz-Latn-UZ"
                                    ? t("uz")
                                    : langCode === "uz-Cyrl-UZ"
                                    ? t("cryl")
                                    : t("en")
                                }
                                inputProps={{
                                  placeholder:
                                    langCode === "ru"
                                      ? t(`in russian`)
                                      : langCode === "uz-Latn-UZ"
                                      ? t("in uzbek(lat)")
                                      : langCode === "uz-Cyrl-UZ"
                                      ? t("in uzbek(kyr)")
                                      : t("in en"),
                                  value: attr.attribute.name[langCode] || "",
                                  onChange: (e) => {
                                    const payload = [...attribute];
                                    payload[index].attribute.name[langCode] =
                                      e.target.value;
                                    setAttribute(payload);
                                  },
                                }}
                              />
                            </div>
                          ))}
                        </div>

                        <div className="d-flex gap-3">
                          {languages.map((langCode) => (
                            <div className="w-100" key={langCode}>
                              <InputLabel
                                errors={errors}
                                label={
                                  langCode === "ru"
                                    ? t("ru")
                                    : langCode === "uz-Latn-UZ"
                                    ? t("uz")
                                    : langCode === "uz-Cyrl-UZ"
                                    ? t("cryl")
                                    : t("en")
                                }
                                inputProps={{
                                  placeholder:
                                    langCode === "ru"
                                      ? t(`Значение`)
                                      : langCode === "uz-Latn-UZ"
                                      ? t("Значение лат")
                                      : langCode === "uz-Cyrl-UZ"
                                      ? t("Значение кир")
                                      : t("Значение анг"),
                                  value: attr.valueTranslations[langCode] || "",
                                  onChange: (e) => {
                                    const payload = [...attribute];
                                    payload[index].valueTranslations[langCode] =
                                      e.target.value;
                                    setAttribute(payload);
                                  },
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="d-flex align-items-center gap-2 justify-content-end mt-2">
                    <Button
                      onClick={addAttribute}
                      type="button"
                      className="d-flex align-items-center gap-2"
                    >
                      {t("add_attribute")}
                    </Button>
                    <Button
                      onClick={handleSubmit(ProductSubmit)}
                      className="d-flex align-items-center gap-2"
                    >
                      {productLoading ? (
                        <Spinner size="sm" className="me-2">
                          Loading...
                        </Spinner>
                      ) : null}
                      {t("Save")}
                      <i className="ri-check-line"></i>
                    </Button>
                  </div>
                </Form>
              )
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default AddProducts;

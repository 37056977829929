import React, { useState } from "react";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import { Table, Button, Row, Col, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { prettify } from "./prettify";
import { config } from "../../../utils/config";

import { PhotoProvider, PhotoView } from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';
const ReserveTable = ({ data, pagination }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState()
  const [images, setImages] = useState([])
  const calculateOrderTotalPrice = (order) => {
    let total = 0;
    order?.subOrders?.forEach((subOrder) => {
      subOrder?.items?.forEach((item) => {
        const itemPrice = item?.variation?.prices?.find(
          (price) => price.type === "Price"
        )?.value;
        if (itemPrice) {
          total += itemPrice * item?.count;
        }
      });
    });
    return total;
  };

  const statusLabel = (state) => {
    switch (state) {
      case 0:
        return t("reserve_created");
      case 1:
        return t("reserve_undrway");
      case 2:
        return t("reserve_sent");
      case 3:
        return t("reserve_complated");
      case 4:
        return t("reserve_cancelled");
      default:
        return t("reserve_refund");
    }
  };

  const paymentType = (type) => {
    switch (type) {
      case 0:
        return t("Terminal");
      case 1:
        return t("Card");
      case 2:
        return t("Cash");
      case 3:
        return t("Wallet");
    }
  };

  const currency = config.currency();


  return (
    <div>
      <div
        style={{
          overflowX: "auto",
          maxHeight: `calc(100vh - 250px)`,
          overflowY: "auto",
        }}
      >
        <Table bordered style={{ minWidth: "1150px" }}>
          <thead
            style={{
              position: "sticky",
              top: -1,
              background: "#ffff",
              zIndex: 999,
            }}
          >
            <tr>
              <th>{t("created_date")}</th>
              <th>{t("order_information")}</th>
              <th>{t("Price")}</th>
              <th>{t("payment_method")}</th>
              <th>{t("status")}</th>
              <th>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data?.map((order, orderIndex) => {
                return order?.subOrders?.map((subOrder, subOrderIndex) => (
                  <React.Fragment key={`order-${orderIndex}`}>
                    {subOrder?.items?.map((item, itemIndex) => (
                      <tr key={`subOrder-${subOrderIndex}-item-${itemIndex}`}>
                        {itemIndex === 0 && (
                          <td rowSpan={subOrder.items.length}>
                            <div
                              className="d-flex flex-column gap-1 align-items-center justify-content-center"
                              style={{
                                position: "sticky",
                                top: 50,
                                zIndex: 99,
                              }}
                            >
                              <span>
                                {" "}
                                {moment(order?.createdDate)
                                  .add(5, "hours")
                                  .format("DD.MM.YYYY")}
                              </span>
                              <span>
                                {" "}
                                {moment(order?.createdDate)
                                  .add(5, "hours")
                                  .format("HH:mm:ss")}
                              </span>
                              <br />
                              <span>+{order?.phoneNumber}</span>
                              <span>{order?.fullName}</span>
                            </div>
                          </td>
                        )}

                        <td
                          className={`d-flex gap-2 flex-column ${
                            itemIndex === subOrder.items.length - 1
                              ? "border-none"
                              : "border-bottom"
                          }`}
                        >
                          {itemIndex === 0 && (
                            <strong style={{ fontSize: "16px" }}>
                              <Link to={`/reserve/${order.id}/${subOrder.id}`}>
                                #{order?.id}
                              </Link>
                            </strong>
                          )}

                          <div className="d-flex gap-4 align-items-start w-100 ">
                          <PhotoProvider>
                          <div
                              style={{
                                backgroundColor: "rgb(239, 239, 239)",
                                minHeight: "80px",
                                width: "80px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "14px",
                              }}
                              
                              >
                          {
                              item?.variation?.files.sort((a,b) => a.order - b.order)?.map((image, index) => (
                              index === 0 && (

                                <PhotoView  key={index} src={image?.url} >

                              <img
                                style={{
                                  cursor: "pointer",
                                  borderRadius: "6px",
                                  margin: "0 auto",
                                  width: "100%",
                                  maxHeight: "80px",
                                  objectFit: "contain",
                                }}
                                src={image?.url}
                                alt={image?.id}
                                />
                              </PhotoView>
                            ))
                          )
                          }
                            </div>
                          </PhotoProvider>
                        



                            <div
                              className="d-flex flex-column justify-content-between"
                              style={{
                                height: "80px",
                                maxHeight: "calc(100% - 120px)",
                              }}
                            >
                              <Link
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  // textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "wrap",
                                  width: "100%",
                                  maxWidth: "100%"
                                }}
                                to={`/products/${item?.variation?.product?.id}`}
                              >
                                {item?.variation?.product?.name}
                              </Link>
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  textAlign: "left",
                                }}
                              >
                                {t("count")}: {item?.count}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          {prettify(
                            item?.variation?.prices?.find(
                              (price) => price.type === "Price"
                            )?.value * item?.count
                          ) +
                            " " +
                            t(currency === "aed" ? "AED" : currency)}
                        </td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center">
                            {paymentType(order?.paymentType)}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center">
                            {statusLabel(subOrder?.state)}
                          </div>
                        </td>
                        {itemIndex === 0 && (
                          <td rowSpan={subOrder.items.length}>
                            <div
                              style={{
                                position: "sticky",
                                top: 50,
                                zIndex: 99,
                              }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <Button
                                style={{ background: "#D5D5D5" }}
                                color="none"
                                size="sm"
                                outline
                                onClick={() =>
                                  navigate(
                                    `/reserve/${order.id}/${subOrder.id}`
                                  )
                                }
                              >
                                {t("read_more")}
                              </Button>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </React.Fragment>
                ));
              })
            ) : (
              <tr>
                <td
                  colSpan={12}
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  {t("No data")}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {pagination && (
        <Row className="justify-content-md-end justify-content-center align-items-center p-2">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() =>
                  pagination?.onChange(pagination?.currentPage - 1)
                }
                disabled={pagination?.currentPage <= 1}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pagination?.currentPage} of {pagination?.totalPages}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pagination?.totalPages}
              defaultValue={pagination?.currentPage}
              readOnly
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() =>
                  pagination?.onChange(pagination?.currentPage + 1)
                }
                disabled={
                  pagination?.totalPages === pagination?.currentPage ||
                  !data?.length
                }
              >
                {">"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ReserveTable;
